import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.boundAutocomplete = this.autocomplete.bind(this)

    document.addEventListener('autocomplete.change', this.boundAutocomplete)
  }

  disconnect () {
    document.removeEventListener('autocomplete.change', this.boundAutocomplete)
  }

  autocomplete (event) {
    if (event.target.classList.contains('form_payment_types')) {
      this.addPaymentTypeToList(event.detail)

      document.getElementById('form_payment_types_search').value = ''
      document.getElementById('form_payment_types').value = ''
    }
  }

  listElementUrl (detail, pricePerVisit) {
    if (pricePerVisit === null) {
      return `/payment_types/${detail.value}/list_element`
    } else {
      return `/payment_types/${detail.value}/list_element?price_per_visit=${pricePerVisit.value}`
    }
  }

  addPaymentTypeToList (detail) {
    const csrfToken = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
    const headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': csrfToken
    }

    const pricePerVisit = document.getElementById('form_price_per_visit')
    const paymentTypesElement = document.getElementById('payment-types-list')
    const paymentTypesChanged = document.getElementById('form_payment_types_changed')

    const url = new URL(this.listElementUrl(detail, pricePerVisit), window.location.href)

    if (paymentTypesChanged !== null) {
      paymentTypesChanged.value = 1
    }

    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        const template = document.createElement('template')
        template.innerHTML = html
        paymentTypesElement.append(template.content.firstChild)
      })
  }
}
