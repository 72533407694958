import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  connect () {
    this.sortable = Sortable.create(
      this.element,
      {
        animation: 150,
        onSort: function (evt) {
          const paymentTypesChanged = document.getElementById('form_payment_types_changed')
          paymentTypesChanged.value = 1
        }
      }
    )
  }

  remove (event) {
    const paymentTypesChanged = document.getElementById('form_payment_types_changed')

    // paymentTypesChanged may not exist if we are creating a new appointment
    if (paymentTypesChanged) {
      paymentTypesChanged.value = 1
    }

    event.target.parentElement.remove()
  }
}
